import React from "react"
import MenuSecondary from "../pageElements/MenuSecondary"
import MenuLegal from "../pageElements/MenuLegal"

import "../../styles/footerContent.scss"

class FooterContent extends React.Component {

  render() {
    let currentYear = new Date().getFullYear();

    return (
      <div className="footer-content">
        <div className="footer-menu">
          <MenuSecondary/>
        </div>
        <div className="footer-copyright footnote">&copy; {currentYear} Blue Tenon. All Rights Reserved.</div>
        <div className="footer-policies">
          <MenuLegal/>
        </div>
      </div>
    )
  }
}

export default FooterContent;