import React from "react"
import { Link } from "gatsby"

class MenuPrimary extends React.Component {

  render() {

    return (
      <div className="menu-primary">
        <ul>
          <li><Link to="/">Home</Link></li>
        </ul>
      </div>

    )
  }
}


export default MenuPrimary;
