import React from "react"
import { Link } from "gatsby"

class MenuSecondary extends React.Component {

  render() {

    return (
      <div className="menu-secondary">
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/contact">Contact Us</Link></li>
        </ul>
      </div>

    )
  }
}

export default MenuSecondary;
