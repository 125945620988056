import "../../styles/LayoutMain.scss"

import React from "react"
import PropTypes from "prop-types"
import HeaderContent from "../pageElements/HeaderContent"
import FooterContent from "../pageElements/FooterContent"
import CookiePolicyBanner from "../cookiePolicy/CookiePolicyBanner"

class Template extends React.Component {


  render() {
    const { children } = this.props;

    return (
      <div className={"page " + this.props.className}>
        <CookiePolicyBanner/>
        <div className="page-content">
          <header className="page-header">
            <HeaderContent/>
          </header>
          <div className="page-body">
            {children}
          </div>
          <footer className="page-footer">
            <FooterContent/>
          </footer>
        </div>

      </div>

    )
  }
}

Template.propTypes = {
  children: PropTypes.array,
  className: PropTypes.string
};

export default Template;
