import React from "react"
import { Link } from "gatsby"

class MenuLegal extends React.Component {

  render() {

    return (
      <div className="menu-legal footnote">
        <ul>
          <li><Link to="/privacy">Privacy Policy</Link></li>
          <li><Link to="/conditions-of-use">Conditions of Use</Link></li>
        </ul>
      </div>

    )
  }
}


export default MenuLegal;
