import React from "react"
import MenuPrimary from "../pageElements/MenuPrimary"
import "../../styles/headerContent.scss"


class HeaderContent extends React.Component {


  render() {

    return (
      <div className="header-content">
      {/*
        <div className="header-logo">
          <div className="logo">


LOGO HERE

          </div>
        </div>
        <div className="header-menu">
          <MenuPrimary/>
        </div>
        <div className="header-button">


BUTTON

        </div>
        <div className="header-sidebar">
        </div>
      */}
      </div>
    )
  }
}



export default HeaderContent;
